import React from "react"


function register() {
    return (
    <div>
        <h1>
            Registro
        </h1>
        <p>
            Llena el formulario de registro
        </p>

    </div>
    )
}
export default register